import React from "react"
import { Helmet } from "react-helmet"
import { ThemeProvider } from "styled-components"
import { Normalize } from "styled-normalize"

import { Header } from "../components"

import { theme } from "../theme"

export default () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          <title>smartive — Eingaben Best of Swiss Web 2021</title>
        </Helmet>
        <Normalize />
        <Header />
      </>
    </ThemeProvider>
  )
}
